/* price per unit */
(function($) {

	var changePPU = function() {
		var target = $($(this).attr('data-ppu-target'));
		if(target.length) {
			$(this).on('change.ppu', function() {
				var selectedSku = $(this).find('option:selected').attr('data-sku-base-id');
				var ppu = $('.product__price-per-unit[data-sku-base-id=' + selectedSku + ']', target);
				if(ppu.length) {
					$('.product__price-per-unit', target).addClass('hidden');
					ppu.removeClass('hidden');
				}
			});
		}
	};

	var pricePerUnit = function(contextNode) {
		$('select[data-ppu-target]', contextNode).each(changePPU);
		$('select[data-ppu-show="true"]', contextNode).trigger('change.ppu');
		$('a[data-ppu-show]', contextNode).on('click', function() {
			var target = $('select' + $(this).attr('data-ppu-show'), contextNode);
			if(target.length) target.trigger('change.ppu');
		});
	};

  $(document).on('MPP:productQV:rendered', function(e, QScontainer) {
    pricePerUnit(QScontainer);
  });

  $(function() {
    pricePerUnit($(this));
  });

})(jQuery);
